import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, patch, post } from '../../apiService';


export const getUser = createAsyncThunk(
  'getUser',
  (id) =>
    new Promise((resolve, reject) => {
      let url = `/user/${id}`
      resolve(get(url));
    }),
);

export const reportUser = createAsyncThunk(
  'reportUser',
  (data) =>
    new Promise((resolve, reject) => {
      let url = `/complaint/`
      resolve(post(url, { data }));
    }),
);