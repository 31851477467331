import React from 'react'

function BlogsCard(props) {
  return (
    <div className='blogCard card p-1'>
    <div className='imgDiv' style={{ backgroundImage: `url('${props.obj.image}')` }} />
    <div className='textDiv'>
        <div className='head1'>
            <h5>{props.obj.title}</h5>
        </div>
        <div className='dateDiv'>
            <p>{props.obj.date}</p>
        </div>
        <div className='text-div'>
            <p>{props.obj.description}</p>
        </div>
        <div class="btn-div mt-3 mb-3">
            <button>Read More</button>
        </div>
    </div>
</div>
  )
}

export default BlogsCard