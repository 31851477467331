import React, { useEffect, useState } from 'react'
import { Badge, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllJobs } from '../../../redux/jobSlice/actions'
import { getAllApplications } from '../../../redux/applicationSlice/actions'
import ReviewModal from '../../../Components/ReviewModal/ReviewModal'

function FinishedJobs() {

  let dispatch = useDispatch()
  let allJobs = useSelector((state) => state.job.allJobs)
  let allApplications = useSelector((state) => state.applications.allApplications)
  let user = useSelector((state) => state.auth.user)
  const [showModal, setShowModal] = useState(false);
  const [selectedCompanion, setSelectedCompanion] = useState("");
  const [selectedJob, setSelectedJob] = useState("");

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const getData = () => {
    if (user) {
      if (user?.role === "loved-one") {
        dispatch(getAllJobs({ status: 'completed' })).then((res) => {
          if (res?.payload?.status === "success") {
            // console.log(res.payload)
          }
        })
      }
      else {
        dispatch(getAllApplications({ status: "finished" })).then((res) => {
          if (res?.payload?.status === "success") {
            // console.log(res.payload)
          }
        })
      }
    }
  }

  useEffect(() => {
    getData()
  }, [user])

  // useEffect(()=>{
  //   console.log(allApplications)
  // },[allApplications])

  const openReviewModal = (a) => {
    console.log(a)
    let obj = a?.applications?.find((a) => a.status === "finished")
    setSelectedCompanion(obj.companion)
    setSelectedJob(obj)
    setShowModal(true)
  }

  return (
    <div className='jobHistoryMain p-3'>
      <ReviewModal show={showModal} handleClose={handleCloseModal} selectedCompanion={selectedCompanion} selectedJob={selectedJob} getData={getData} />
      <form>
        <div className='searchInput'>
          <input type='text' placeholder='Search here ...' />
          <div className='searchIconBtn'>
            <button type='submit'>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </button>
          </div>
        </div>
      </form>
      <div className='tableDiv'>
        <Table bordered hover>
          <thead>
            {user?.role === 'loved-one' ?
              <tr>
                <th>S. no</th>
                <th>Job Title</th>
                <th>Rate</th>
                <th>Total</th>
                <th>Status</th>
                <th>Date</th>
                <th>More</th>
              </tr>
              :
              <tr>
                <th>S. no</th>
                <th>Job Title</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            }
          </thead>
          <tbody>
            {user?.role === "loved-one" ? allJobs?.map((a, i) => (
              <tr key={i}>
                <td className='text-center'>{i + 1}</td>
                <td>{a.title}</td>
                <td>${a.budget}</td>
                <td>${a.budget}</td>
                <td className='text-center'>
                  {a.status === "approved" ?
                    <Badge pill bg="primary">
                      Approved
                    </Badge>
                    :
                    a.status === "completed" ?
                      <Badge pill bg="success">
                        Completed
                      </Badge>
                      :
                      a.status === "rejected" ?
                        <Badge pill bg="danger">
                          Rejected
                        </Badge>
                        :
                        a.status === "hired" ?
                          <Badge pill bg="info">
                            Hired
                          </Badge>
                          :
                          <Badge pill bg="secondary">
                            Pending
                          </Badge>
                  }
                </td>
                <td>{a.createdAt.split("T")[0]}</td>
                <td className='text-center'>
                  {!a.reviewed ?
                    <div className='btn-div'>
                      <button onClick={() => openReviewModal(a)} className='primary'>Give Review</button>
                    </div>
                    :
                    <div className='btn-div'>
                      <button className='primary'>Completed</button>
                    </div>
                  }
                </td>
              </tr>
            ))
              :
              allApplications?.map((a, i) => (
                <tr key={i}>
                  <td className='text-center'>{i + 1}</td>
                  <td>{a.job.title}</td>
                  <td>${a.amount}</td>
                  <td className='text-center'>
                    {a.status === "applied" ?
                      <Badge pill bg="primary">
                        Applied
                      </Badge>
                      :
                      a.status === "rejected" ?
                        <Badge pill bg="danger">
                          Rejected
                        </Badge>
                        :
                        a.status === "inProgress" ?
                          <Badge pill bg="secondary">
                            In Progress
                          </Badge>
                          :
                          <Badge pill bg="success">
                            Finished
                          </Badge>
                    }
                  </td>
                  <td>{a.createdAt.split("T")[0]}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default FinishedJobs