import { createSlice } from '@reduxjs/toolkit';
import * as Actions from './actions';
import { addCaseWithLoading } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  allApplications: null
};

const applicationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    addCaseWithLoading(builder, Actions.getAllApplications, {
      onCompleted: (state, action) => {
        console.log(action)
        state.isLoading = false;
        state.allApplications = action.payload.data
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error
      },
    });
  },
});

export default applicationSlice.reducer;