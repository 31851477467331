import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Image, Modal, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, reportUser } from '../../redux/userSlice/actions';

import './UserProfile.scss'
import { showErrorToast } from '../../apiService';

function UserProfile() {

    let dispatch = useDispatch()

    let user = useSelector((state) => state.user?.user)

    const [reason, setReason] = useState('');
    const [show, setShow] = useState(false);

    useEffect(() => {
        let id = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]
        dispatch(getUser(id))
    }, [])

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    const report = () => {
        if (!reason) {
            showErrorToast("Select any reason")
        } else {
            let obj = {
                reportTo: user?._id,
                reportReason: reason
            }

            dispatch(reportUser(obj)).then((res)=>{
                setShow(false)
            })
        }
    }

    return (
        <div className='userProfile'>
            <Modal show={show} centered onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Report User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Select reason for reporting:</Form.Label>
                        <Form.Control as="select" value={reason} onChange={handleReasonChange}>
                            <option value="">Select Reason</option>
                            <option value="Spam">Spam</option>
                            <option value="Abusive behavior">Abusive behavior</option>
                            <option value="Inappropriate content">Inappropriate content</option>
                            <option value="Other">Other</option>
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <div className='btn-div' style={{ columnGap: 10, display: "flex" }}>
                        <button onClick={() => setShow(false)} className='secondary'>Close</button>
                        <button onClick={report} className='primary'>Report</button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Container>
                <Row className="justify-content-md-center align-items-center">
                    <Col md="12">
                        <Card className="mt-5 mb-5 p-3">
                            <Card.Body>
                                <div className='btn-div'>
                                    <button onClick={() => setShow(true)} className='primary'>Report</button>
                                </div>
                                <Row>
                                    <Col md="4">
                                        <Image height={300} width={300} src={user.image ?? "https://script.viserlab.com/microlab/assets/images/user/profile/user.png"} fluid style={{ borderRadius: 10 }} />
                                    </Col>
                                    <Col md="8" className='d-flex flex-column justify-content-center'>
                                        <h4>{user.name}</h4>
                                        <p><strong>Email:</strong> {user.email}</p>
                                        <p><strong>Verified:</strong> {user.verified ? 'Yes' : 'No'}</p>
                                        <p><strong>Member Since:</strong> {new Date(user.createdAt).toLocaleDateString()}</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default UserProfile