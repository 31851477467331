import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

function Dashboard() {
    return (
        <div className='dashboardMain'>
            <Row className='row-gap-4'>
                <Col md={4}>
                    <div className='overViewDiv balance'>
                        <div className='heading'>
                            <h6>Balance</h6>
                            <h1>$50</h1>
                        </div>
                        <div className='detailDiv'>
                            <NavLink to="/user/transactions">View Details</NavLink>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className='overViewDiv deposit'>
                        <div className='heading'>
                            <h6>Deposit</h6>
                            <h1>$50</h1>
                        </div>
                        <div className='detailDiv'>
                            <NavLink to="/user/transactions">View Details</NavLink>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className='overViewDiv withdraw'>
                        <div className='heading'>
                            <h6>Withdraw</h6>
                            <h1>$0</h1>
                        </div>
                        <div className='detailDiv'>
                            <NavLink to="/user/transactions">View Details</NavLink>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className='overViewDiv completedJobs'>
                        <div className='heading'>
                            <h6>COMPLETED JOB</h6>
                            <h1>0</h1>
                        </div>
                        <div className='detailDiv'>
                            <NavLink to="/user/transactions">View Details</NavLink>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className='overViewDiv jobsCreated'>
                        <div className='heading'>
                            <h6>JOBS CREATED</h6>
                            <h1>0</h1>
                        </div>
                        <div className='detailDiv'>
                            <NavLink to="/user/transactions">View Details</NavLink>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className='overViewDiv transactions'>
                        <div className='heading'>
                            <h6>TRANSACTIONS</h6>
                            <h1>1</h1>
                        </div>
                        <div className='detailDiv'>
                            <NavLink to="/user/transactions">View Details</NavLink>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Dashboard