import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Rating from 'react-rating-stars-component';
import { useDispatch } from 'react-redux';
import { createReview } from '../../redux/jobSlice/actions';

const ReviewModal = ({ show, handleClose, selectedCompanion, selectedJob, getData }) => {
    const [rating, setRating] = useState(0);
    const [description, setDescription] = useState('');

    let dispatch = useDispatch()

    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleSubmit = () => {
        let obj = {
            rating: rating,
            review: description,
            reviewTo: selectedCompanion,
            job: selectedJob.job
        }
        dispatch(createReview(obj)).then((res) => {
            if (res?.payload?.status === "success") {
                getData()
                handleClose()
            }
        })
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Leave a Review</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Rating:</Form.Label>
                        <div className="d-flex justify-content-center mb-3">
                            <Rating
                                count={5}
                                onChange={handleRatingChange}
                                size={40}
                                activeColor="#ffd700"
                                value={rating}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={description}
                            onChange={handleDescriptionChange}
                            placeholder="Write your review here..."
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <div className='btn-div' style={{ columnGap: 10, display: "flex" }}>
                    <button onClick={handleClose} className='secondary'>Close</button>
                    <button onClick={handleSubmit} className='primary'>Submit Review</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ReviewModal;
