import React, { useEffect } from 'react'
import Header from './Common/Header/Header'
import Footer from './Common/Footer/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetail } from './redux/authSlice/actions'

function Layout(props) {

    let dispatch = useDispatch()

    let user = useSelector((state) => state.auth.user)


    useEffect(() => {
        dispatch(getUserDetail()).then((res) => {
            if (res.payload?.status === "success") {

            }
        })
    }, [])

    return (
        <>
            <Header />
            {props.children}
            <Footer />
        </>
    )
}

export default Layout