import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./authSlice/AuthSlice";
import JobSlice from "./jobSlice/JobSlice";
import applicationSlice from "./applicationSlice/ApplicationSlice";
import thunk from "redux-thunk";
import userSlice from "./userSlice/UserSlice";

const rootReducer = combineReducers({
    auth: AuthSlice,
    job: JobSlice,
    applications: applicationSlice,
    user: userSlice,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: () => {
        return [thunk]
    },
});

export { store };