import React from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { getFormValues } from '../../../helper'
import { loginUser } from '../../../redux/authSlice/actions'

function Login() {

    let dispatch = useDispatch()

    let error = useSelector(state => state.auth.error)
    let navigate = useNavigate()

    const login = (e) => {
        e.preventDefault()
        let obj = getFormValues(e)
        console.log(obj)
        dispatch(loginUser({ payload: obj })).then((res) => {
            if (res.payload?.status === "success") {
                localStorage.setItem("token", res.payload?.token)
                navigate("/user/dashboard")
            }
        })
    }

    return (
        <div className=' authMain loginMain container-fluid'>
            <Row className='h-100'>
                <Col md={6} className='h-100 p-0'>
                    <div className='imageDiv d-flex flex-column align-items-center justify-content-center row-gap-5'>
                        {/* <div className='logoDiv'>
                            <img width={100} src={require('../../../assets/images/logo.png')} />
                        </div> */}
                        <div className='imgDiv text-center'>
                            <img width={"80%"} src={require('../../../assets/images/person.png')} />
                        </div>
                    </div>
                </Col>
                <Col md={6} className='h-100 p-0'>
                    <div className='formDiv d-flex flex-column align-items-center justify-content-center login h-100'>
                        <div className='heading w-100'>
                            <div className='w-100 d-flex align-items-center justify-content-between'>
                                <h6>Welcome to <span>Kaytree</span></h6>
                            </div>
                            <h2>Sign In</h2>
                        </div>

                        <form onSubmit={(e) => login(e)}>
                            <div className='inputDiv'>
                                <label>Email</label>
                                <input name='email' type='text' placeholder='Type your Email' />
                            </div>
                            <div className='inputDiv'>
                                <label>Password</label>
                                <input name='password' type='password' placeholder='Type your Password' />
                            </div>

                            <div className='rememberMe d-flex align-items-center justify-content-between'>
                                <div>
                                    <Form.Check
                                        type="checkbox"
                                        id={`default`}
                                        label={`Remeber Login`}
                                    />
                                </div>
                                <div>
                                    <NavLink to="/forgot-password">Forgot Password ?</NavLink>
                                </div>
                            </div>

                            {/* {error?.message &&
                                <div className='error mt-2'>
                                    <Alert variant="danger">
                                        {error?.message}
                                    </Alert>
                                </div>
                            } */}

                            <div className='btn-div'>
                                <button type='submit'>Login</button>
                            </div>
                        </form>

                        <div className='formBelow text-center'>
                            <p>Don’t have an account?  <NavLink className='ml-1' to="/register">Sign Up</NavLink></p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Login