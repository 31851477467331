import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorToast } from '../../apiService'
import { Button, Modal } from 'react-bootstrap'
import { applyJob, checkAllreadyApplied } from '../../redux/applicationSlice/actions'
import { NavLink } from 'react-router-dom'

function JobCard(props) {

    let user = useSelector((state) => state.auth.user)
    let dispatch = useDispatch()

    let [modalApply, setModalApply] = useState(false)

    let [job, setJob] = useState(null)

    const [formData, setFormData] = useState({
        description: '',
        amount: '',
    });

    const applyJobModal = (job) => {
        if (!user) {
            showErrorToast("To apply, you must be logged in.")
        }
        else if (user?.role === "loved-one") {
            showErrorToast("To apply, please log in as a companion.")
        }
        else if (!user?.identityVerified) {
            showErrorToast("To apply, please confirm your identity.")
        }
        else if (user?.suspended) {
            showErrorToast("Your account has been suspended.")
        }
        else {
            dispatch(checkAllreadyApplied({ id: job?._id })).then((res) => {
                if (res.payload?.status === "success") {
                    setJob(job)
                    setModalApply(true)
                }
            })
        }
    }

    const handleClose = () => setModalApply(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const submitProposal = (e) => {
        e.preventDefault()
        let obj = formData
        obj.createdBy = job?.createdBy?._id
        obj.job = job?._id

        dispatch(applyJob({ payload: obj })).then((res) => {
            if (res.payload?.status === "success") {
                setModalApply(false)
            }
        })

    }

    return (
        <div className='jobCard'>
            <Modal show={modalApply} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{job?.title}</Modal.Title>
                </Modal.Header>
                <form onSubmit={(e) => submitProposal(e)}>
                    <Modal.Body>
                        <div className='inputDiv'>
                            <label>Amount</label>
                            <input
                                required
                                type='number'
                                placeholder='Amount'
                                value={formData.amount}
                                onChange={handleInputChange}
                                name='amount'
                            />
                        </div>
                        <div className='inputDiv mt-3'>
                            <label>Description</label>
                            <textarea
                                required
                                rows={5}
                                value={formData.description}
                                onChange={handleInputChange}
                                name='description'
                            ></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='btn-div d-flex'>
                            <button type='button' className='secondary' onClick={handleClose}>Close</button>
                            <button type='submit' className='primary'>Submit</button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
            <div style={{ backgroundColor: props.obj.bg }} className='cover'>
                <div className='img-div'>
                    <img style={{ backgroundColor: props.obj.bg }} src={props.job?.createdBy?.image ? props.job?.createdBy?.image : "https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png"} />
                </div>
            </div>
            <div className='content'>
                {/* <div className='vacancy'>
                    <div className='tagVac'>
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.87467 2.08333C7.04043 2.08333 7.19941 2.01749 7.31662 1.90028C7.43383 1.78306 7.49967 1.62409 7.49967 1.45833C7.49967 1.29257 7.43383 1.1336 7.31662 1.01639C7.19941 0.899181 7.04043 0.833333 6.87467 0.833333C6.70891 0.833333 6.54994 0.899181 6.43273 1.01639C6.31552 1.1336 6.24967 1.29257 6.24967 1.45833C6.24967 1.62409 6.31552 1.78306 6.43273 1.90028C6.54994 2.01749 6.70891 2.08333 6.87467 2.08333ZM0.245508 3.99167L3.99551 0.241667C4.14551 0.0916666 4.35384 0 4.58301 0H7.49967C7.96217 0 8.33301 0.370833 8.33301 0.833333V3.75C8.33301 3.97917 8.24134 4.1875 8.08717 4.3375L4.34134 8.0875C4.18717 8.2375 3.97884 8.33333 3.74967 8.33333C3.52051 8.33333 3.31217 8.2375 3.16217 8.0875L0.245508 5.17083C0.091341 5.02083 -0.000325203 4.8125 -0.000325203 4.58333C-0.000325203 4.35 0.0955076 4.14167 0.245508 3.99167Z" fill="#344C11" />
                        </svg>
                        Vacancy Available
                    </div>
                    <div className='tagCount'>
                        391
                    </div>
                </div> */}
                <div className='heading'>
                    <h6>{props.job.title}</h6>
                </div>
                <div className='postedBy'>
                    <NavLink to={`/profile/${props?.job?.createdBy?._id}`}>
                        <span className='span2'>{props?.job?.createdBy?.name}</span>
                    </NavLink>
                </div>
                <div className='publishDiv'>
                    <span>Published</span>
                    <span className='span2'>{props.job.createdAt.split("T")[0]}</span>
                </div>
                <hr />
                <div className='pricingDiv'>
                    <span>${props.job.budget}</span>
                    <div className='btn-div'>
                        <button onClick={() => applyJobModal(props.job)}>Apply</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobCard