import React from 'react'
import GetStarted from '../../Components/GetStarted/GetStarted'
import { Col, Row } from 'react-bootstrap'
import BlogsCard from '../../Components/GetStarted/BlogsCard'

let blogs = [
    {
        title: "placeat facere possimus, omnis voluptas assumenda est...",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore...",
        image: "https://script.viserlab.com/microlab/assets/images/frontend/blog/thumb_638eeb82a9b881670310786.jpg",
        date: "2022-12-05 11:43 PM"
    },
    {
        title: "placeat facere possimus, omnis voluptas assumenda est...",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore...",
        image: "https://script.viserlab.com/microlab/assets/images/frontend/blog/thumb_638eeb64dea041670310756.jpg",
        date: "2022-12-05 11:43 PM"
    },
    {
        title: "placeat facere possimus, omnis voluptas assumenda est...",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore...",
        image: "https://script.viserlab.com/microlab/assets/images/frontend/blog/thumb_638eeb41f0bec1670310721.jpg",
        date: "2022-12-05 11:43 PM"
    },
    {
        title: "placeat facere possimus, omnis voluptas assumenda est...",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore...",
        image: "https://script.viserlab.com/microlab/assets/images/frontend/blog/thumb_638eeb29c439a1670310697.jpg",
        date: "2022-12-05 11:43 PM"
    },
    {
        title: "placeat facere possimus, omnis voluptas assumenda est...",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore...",
        image: "https://script.viserlab.com/microlab/assets/images/frontend/blog/thumb_638eeb0f0d3141670310671.jpg",
        date: "2022-12-05 11:43 PM"
    },
    {
        title: "placeat facere possimus, omnis voluptas assumenda est...",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore...",
        image: "https://script.viserlab.com/microlab/assets/images/frontend/blog/thumb_638eeaed7c2f31670310637.jpg",
        date: "2022-12-05 11:43 PM"
    },
]

function Blogs() {
    return (
        <div className='FaqsMain'>
            <div className='banner-sec mb-2'>
                <div className='container d-flex align-items-center justify-content-center h-100'>
                    <div className='heading'>
                        <h1>Blogs</h1>
                    </div>
                </div>
            </div>

            <div className='container pt-5 pb-5'>
                <Row style={{ rowGap: 30 }}>
                    {blogs.map((a, i) => (
                        <Col md={4} key={i}>
                            <BlogsCard obj={a} />
                        </Col>
                    ))}
                </Row>
            </div>
            <GetStarted />
        </div>
    )
}

export default Blogs