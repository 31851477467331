import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import section2img from '../../assets/images/section2img.png'
import section3img from '../../assets/images/section3img.png'
import person1 from '../../assets/images/person1.png'
import person2 from '../../assets/images/person2.png'
import person3 from '../../assets/images/person3.png'
import person4 from '../../assets/images/person4.png'
import person5 from '../../assets/images/person5.png'
import person6 from '../../assets/images/person6.png'
import person7 from '../../assets/images/person7.png'
import person8 from '../../assets/images/person8.png'

import cat1 from '../../assets/images/cat1.png'
import cat2 from '../../assets/images/cat2.png'
import cat3 from '../../assets/images/cat3.png'
import cat4 from '../../assets/images/cat4.png'
import cat5 from '../../assets/images/cat5.png'
import cat6 from '../../assets/images/cat6.png'
import cat7 from '../../assets/images/cat7.png'
import cat8 from '../../assets/images/cat8.png'


import './Home.scss'
import Slider from 'react-slick'
import GetStarted from '../../Components/GetStarted/GetStarted'
import JobCard from '../../Components/GetStarted/JobCard'
import { useDispatch, useSelector } from 'react-redux'
import { getAllFrontendJobs } from '../../redux/jobSlice/actions'

let persons = [
    {
        img: person1,
        bg: "#643D46"
    },
    {
        img: person2,
        bg: "#C56320"
    },
    {
        img: person3,
        bg: "#603D10"
    },
    {
        img: person4,
        bg: "#501068"
    },
    {
        img: person5,
        bg: "#126B8E"
    },
    {
        img: person6,
        bg: "#1B8463"
    },
    {
        img: person7,
        bg: "#730000"
    },
    {
        img: person8,
        bg: "#006D6B"
    },
]

function Home() {

    let dispatch = useDispatch()
    let allJobs = useSelector((state) => state.job.allJobs)

    useEffect(() => {
        dispatch(getAllFrontendJobs()).then((res) => {
            if (res?.payload?.status === "success") {
                // console.log(res.payload)
            }
        })
    }, [])


    var settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
        ]
    };

    return (
        <div className='homeMain'>
            <div className='bg-div'>
                <section className='sectionHero'>
                    <Container>
                        <div className='heading'>
                            <h5>Get Started</h5>
                            <h1>Empowering <span>Youth</span>,<br />
                                Enriching <span>Lives</span>,<br />
                                Connecting <span>Generations</span></h1>
                        </div>
                        <div className='text-div'>
                            <p>Join the enriching Kaytree community where generations come together to make a difference.</p>
                        </div>
                        <div className='searchDiv'>
                            <form>
                                <div className='inputDiv'>
                                    <select>
                                        <option disabled>Select Category</option>
                                        <option>Household Help</option>
                                        <option>Healthcare Appointment</option>
                                        <option>Salon/SPA Appointment</option>
                                        <option>Grocery Runs</option>
                                        <option>Travel Companions</option>
                                        <option>Personal Driver</option>
                                        <option>Lively- hood & Exercise</option>
                                        <option>Shopping</option>
                                    </select>
                                </div>
                                <div className='inputDiv'>
                                    <input type='text' placeholder='Search jobs...' />
                                </div>
                                <div className='btn-div'>
                                    <button>Search</button>
                                </div>
                            </form>
                        </div>

                        <div className='popuCategory'>
                            <h5>Popular Jobs Category</h5>
                            <div className='tags'>
                                <div>Personal Drivers</div>
                                <div>Grocery Runs</div>
                                <div>Shopping</div>
                                <div>Travel Companions</div>
                            </div>
                        </div>
                    </Container>
                </section>
                <section className='section1'>
                    <Container>
                        <Row>
                            <Col md={4}>
                                <div className='totalCard'>
                                    <h1>100K+</h1>
                                    <p>total job posts</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='totalCard'>
                                    <h1>500K+</h1>
                                    <p>COMPLETED jobs</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='totalCard'>
                                    <h1>50K+</h1>
                                    <p>REGISTERED USERS</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className='section2'>
                    <Container>
                        <Row>
                            <Col md={5}>
                                <div className='img-div'>
                                    <img src={section2img} />
                                </div>
                            </Col>
                            <Col md={7}>
                                <div className='contentDiv'>
                                    <div className='heading'>
                                        <h6>Help us bridge the gap!</h6>
                                        <h2>Earn while creating connections with compassionate micro-jobs!</h2>
                                    </div>
                                    <div className='text-div'>
                                        <p>Indulge in stable cash flow while engaging with senior citizens and their life stories by being a part of their day-to endeavors with our Innovative micro job site.</p>
                                    </div>
                                    <div className='btn-div'>
                                        <button>Find A Job</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>

            <section className='section2 section3'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className='contentDiv'>
                                <div className='heading'>
                                    <h6>Make life less complicated!</h6>
                                    <h2>Connect with loyal, reliable and accessible helpers.</h2>
                                </div>
                                <div className='text-div'>
                                    <p>Helping seniors connect with qualified youngsters with best micro jobs online, through accessible companionship and assistance with daily tasks, improving quality of life.</p>
                                </div>
                                <div className='btn-div'>
                                    <button>Post A Job</button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='img-div'>
                                <img src={section3img} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='section4'>
                <Container>
                    <div className='heading'>
                        <h2>Latest Job Posts</h2>
                    </div>
                    <Row>
                        {allJobs?.map((a, i) => (
                            <Col md={3} key={i}>
                                <JobCard job={a} obj={persons[Math.floor((Math.random() * 8))]} />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>

            <section className='section5'>
                <Container>
                    <div className='heading text-center'>
                        <h2>Our top featured helping-hands!</h2>
                    </div>
                    <div className='text-div text-center'>
                        <p>Meet the top-featured helping-hands! Achieve accessibility, company, and reliable connections with Kaytree.</p>
                    </div>
                    <div className='sliderDiv'>
                        <Slider {...settings}>
                            <div className='helpingHandCard'>
                                <div className='top'>
                                    <div className='img-div'>
                                        <img src={person4} />
                                    </div>
                                    <div className='heading'>
                                        <h6>Griffin Mcfarland</h6>
                                        <p>[Email is protected for the demo]</p>
                                    </div>
                                </div>
                                <div className='bottom'>
                                    <div className='flex-div'>
                                        <span>Country</span>
                                        <span>Morocco</span>
                                    </div>
                                    <div className='flex-div'>
                                        <span><b>Jobs Completed</b></span>
                                        <span><b>1</b></span>
                                    </div>
                                </div>
                            </div>
                            <div className='helpingHandCard'>
                                <div className='top'>
                                    <div className='img-div'>
                                        <img src={person2} />
                                    </div>
                                    <div className='heading'>
                                        <h6>Griffin Mcfarland</h6>
                                        <p>[Email is protected for the demo]</p>
                                    </div>
                                </div>
                                <div className='bottom'>
                                    <div className='flex-div'>
                                        <span>Country</span>
                                        <span>Morocco</span>
                                    </div>
                                    <div className='flex-div'>
                                        <span><b>Jobs Completed</b></span>
                                        <span><b>1</b></span>
                                    </div>
                                </div>
                            </div>
                            <div className='helpingHandCard'>
                                <div className='top'>
                                    <div className='img-div'>
                                        <img src={person8} />
                                    </div>
                                    <div className='heading'>
                                        <h6>Griffin Mcfarland</h6>
                                        <p>[Email is protected for the demo]</p>
                                    </div>
                                </div>
                                <div className='bottom'>
                                    <div className='flex-div'>
                                        <span>Country</span>
                                        <span>Morocco</span>
                                    </div>
                                    <div className='flex-div'>
                                        <span><b>Jobs Completed</b></span>
                                        <span><b>1</b></span>
                                    </div>
                                </div>
                            </div>
                            <div className='helpingHandCard'>
                                <div className='top'>
                                    <div className='img-div'>
                                        <img src={person3} />
                                    </div>
                                    <div className='heading'>
                                        <h6>Griffin Mcfarland</h6>
                                        <p>[Email is protected for the demo]</p>
                                    </div>
                                </div>
                                <div className='bottom'>
                                    <div className='flex-div'>
                                        <span>Country</span>
                                        <span>Morocco</span>
                                    </div>
                                    <div className='flex-div'>
                                        <span><b>Jobs Completed</b></span>
                                        <span><b>1</b></span>
                                    </div>
                                </div>
                            </div>
                            <div className='helpingHandCard'>
                                <div className='top'>
                                    <div className='img-div'>
                                        <img src={person7} />
                                    </div>
                                    <div className='heading'>
                                        <h6>Griffin Mcfarland</h6>
                                        <p>[Email is protected for the demo]</p>
                                    </div>
                                </div>
                                <div className='bottom'>
                                    <div className='flex-div'>
                                        <span>Country</span>
                                        <span>Morocco</span>
                                    </div>
                                    <div className='flex-div'>
                                        <span><b>Jobs Completed</b></span>
                                        <span><b>1</b></span>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </Container>
            </section>

            <section className='section6'>
                <Container>
                    <div className='heading text-center'>
                        <h2>Easy Search By Category</h2>
                    </div>
                    <div className='text-div text-center'>
                        <p>Discover rewarding remote micro jobs and opportunities to make a difference! We're seeking compassionate assistance companionship and daily support.</p>
                    </div>

                    <div className='categoryDivMain'>
                        <Row>
                            <Col md={3}>
                                <div style={{ backgroundImage: `url(${cat1})` }} className='categoryDiv'>
                                    <div className='heading'>
                                        <h4>Household Help</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div style={{ backgroundImage: `url(${cat2})` }} className='categoryDiv'>
                                    <div className='heading'>
                                        <h4>Healthcare<br />
                                            Appointment</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div style={{ backgroundImage: `url(${cat3})` }} className='categoryDiv'>
                                    <div className='heading'>
                                        <h4>Salon/SPA<br />
                                            Appointment</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div style={{ backgroundImage: `url(${cat4})` }} className='categoryDiv'>
                                    <div className='heading'>
                                        <h4>Grocery<br />
                                            Runs</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div style={{ backgroundImage: `url(${cat5})` }} className='categoryDiv'>
                                    <div className='heading'>
                                        <h4>Travel<br />
                                            Companions</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div style={{ backgroundImage: `url(${cat6})` }} className='categoryDiv'>
                                    <div className='heading'>
                                        <h4>Personal<br />
                                            Driver</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div style={{ backgroundImage: `url(${cat7})` }} className='categoryDiv'>
                                    <div className='heading'>
                                        <h4>Lively- hood<br />
                                            & Exercise</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div style={{ backgroundImage: `url(${cat8})` }} className='categoryDiv'>
                                    <div className='heading'>
                                        <h4>Shopping</h4>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <GetStarted />
        </div>
    )
}

export default Home