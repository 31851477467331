import React, { useEffect, useRef, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'

import './VerificationModal.scss'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resendOtpCode, verifyEmail, verifyOtpReset } from '../../redux/authSlice/actions';

function VerificationModal(props) {
    const [otpValues, setOtpValues] = useState(['', '', '', '', '', '']); // Initialize state for OTP values
    const [isButtonActive, setIsButtonActive] = useState(false); // State to track button activation

    const inputsRef = useRef([]); // Create a ref for input elements

    let [successMsg, setSuccessMsg] = useState("")
    let [errMsg, setErrMsg] = useState("")

    // let loading = useSelector(state => state.user.loading)
    let error = useSelector(state => state.auth.error)


    let navigate = useNavigate()
    let dispatch = useDispatch()


    useEffect(() => {
        if (inputsRef.current[0]) {
            inputsRef.current[0].focus(); // Focus on the first input field when the component loads
        }
    }, [props.show]);

    const verifyOtp = (otp) => {

        if (props.resetPass) {
            dispatch(verifyOtpReset({ payload: { otp: otp } })).then((res) => {
                if (res.payload?.status === "success") {
                    let token = res.payload?.data?.token
                    localStorage.setItem("resetToken", token)
                    navigate('/reset-password')
                }
            })
        } else {
            dispatch(verifyEmail({ payload: { otp: otp } })).then((res) => {
                if (res.payload?.status === "success") {
                    navigate('/login')
                }
            })
        }

    }

    const handleInputChange = (index, value) => {
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);

        if (value && index < otpValues.length - 1) {
            inputsRef.current[index + 1].removeAttribute('disabled');
            inputsRef.current[index + 1].focus();
        }

        if (value === '' && index > 0) {
            inputsRef.current[index - 1].focus();
        }

        const isButtonActive = newOtpValues.every((val) => val !== '');
        setIsButtonActive(isButtonActive);

        if (index === 5) {
            let otp = newOtpValues.join("")
            verifyOtp(otp)
        }

    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedValue = e.clipboardData.getData('text');
        const pastedValues = pastedValue.split('');

        pastedValues.forEach((value, index) => {
            if (inputsRef.current[index]) {
                inputsRef.current[index].value = value;
                handleInputChange(index, value);
            }
        });
    };


    const resendOtp = () => {
        dispatch(resendOtpCode({ payload: { email: props.email } })).then((res) => {
            if (res.payload?.status === "success") {
                setSuccessMsg("New OTP has been Sent to your email.")
            }
        })
    }


    return (
        <div className='verificationModalMain'>
            <Modal size='lg' centered show={props.show}>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-12 modalMain">
                            <div className="card bg-white mb-5 mt-5 border-0" style={{ boxShadow: '0 12px 15px rgba(0, 0, 0, 0.02)' }}>
                                <div className="card-body p-5 text-center">
                                    <h4>Email Verification</h4>
                                    <p>Enter the code we just send on your<br />
                                        email: <a href='#'>{props.email}</a></p>

                                    <div className="otp-field mb-4">
                                        {otpValues.map((value, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                value={value}
                                                onChange={(e) => handleInputChange(index, e.target.value)}
                                                onPaste={handlePaste}
                                                ref={(input) => (inputsRef.current[index] = input)}
                                                disabled={index > 0 && !otpValues[index - 1]}
                                            />
                                        ))}
                                    </div>
                                    {/* {loading ?
                                        <div>
                                            <Spinner size='sm' animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>
                                        : null} */}
                                    {error?.message && !successMsg ?
                                        <div className='errorMsg'>
                                            <small>{error?.message}</small>
                                        </div>
                                        :
                                        successMsg ?
                                            <div className='successMsg'>
                                                <small>{successMsg}</small>
                                            </div>
                                            :
                                            null
                                    }
                                    <a className='wrong' href='#'>Entered a wrong email?</a>
                                    <p className='resend'>Don't receive the code? <a href='#' onClick={resendOtp}>Resend</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default VerificationModal