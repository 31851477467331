import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getFormValues } from '../../../helper'
import { createJob } from '../../../redux/jobSlice/actions'
import { showErrorToast } from '../../../apiService'

function CreateJob() {

    let user = useSelector((state) => state.auth.user)
    let dispatch = useDispatch()

    const [formData, setFormData] = useState({
        category: '',
        title: '',
        budget: '',
        totalBudget: '',
        type: '',
        description: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };


    const postJob = (e) => {
        e.preventDefault()
        if (!user?.identityVerified) {
            showErrorToast("To apply, please confirm your identity.")
        } else {
            dispatch(createJob({ payload: formData })).then((res) => {
                if (res.payload?.status === "success") {
                    clearForm()
                }
            })
        }
    }

    const clearForm = () => {
        setFormData({
            category: '',
            title: '',
            budget: '',
            totalBudget: '',
            type: '',
            description: ''
        });
    };

    return (
        <div className='createJobMain shadow p-3'>
            <div className='formDiv'>
                <form onSubmit={(e) => postJob(e)}>
                    <div className="inputDiv">
                        <label>Category<sup className='text-red'>*</sup></label>
                        <select
                            value={formData.category}
                            onChange={handleInputChange}
                            name='category'
                            required
                        >
                            <option disabled>Select Category</option>
                            <option>Household Help</option>
                            <option>Healthcare Appointment</option>
                            <option>Salon/SPA Appointment</option>
                            <option>Grocery Runs</option>
                            <option>Travel Companions</option>
                            <option>Personal Driver</option>
                            <option>Lively- hood & Exercise</option>
                            <option>Shopping</option>
                        </select>
                    </div>
                    <div className="inputDiv">
                        <label>Job Title<sup className='text-red'>*</sup></label>
                        <input
                            value={formData.title}
                            onChange={handleInputChange}
                            name='title'
                            type='text'
                            required
                        />
                    </div>
                    <Row>
                        <Col md={4}>
                            <div className="inputDiv">
                                <label>Worker will earn<sup className='text-red'>*</sup></label>
                                <input
                                    value={formData.budget}
                                    onChange={handleInputChange}
                                    name='budget'
                                    type='number'
                                    required
                                />
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="inputDiv">
                                <label>Total Budget</label>
                                <input disabled name='totalBudget' type='number' />
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="inputDiv">
                                <label>Job Type<sup className='text-red'>*</sup></label>
                                <select
                                    value={formData.type}
                                    onChange={handleInputChange}
                                    name='type'
                                    required
                                >
                                    <option>Fixed</option>
                                    <option>Hourly</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <div className="inputDiv">
                        <label>Job Description<sup className='text-red'>*</sup></label>
                        <textarea
                            value={formData.description}
                            onChange={handleInputChange}
                            name='description'
                            rows={6}
                            required
                        />
                    </div>

                    <div className='btn-div'>
                        <button type='submit'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateJob