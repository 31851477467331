import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, patch, post } from '../../apiService';


export const createJob = createAsyncThunk(
  'job',
  ({ payload }) =>
    new Promise((resolve, reject) => {
      resolve(post(`job`, payload));
    }),
);

export const getAllJobs = createAsyncThunk(
  'getAllJobs',
  (payload) =>
    new Promise((resolve, reject) => {
      let url = 'job/getpostedjobs?'
      if (payload) {
        for (let keys in payload) {
          url += `${keys}=${payload[keys]}`
        }
      }
      resolve(get(url));
    }),
);

export const getAllFrontendJobs = createAsyncThunk(
  'getAllFrontendJobs',
  (payload) =>
    new Promise((resolve, reject) => {
      let url = 'job'
      resolve(get(url));
    }),
);

export const getPostedJobs = createAsyncThunk(
  'getPostedJobs',
  (payload) =>
    new Promise((resolve, reject) => {
      let url = 'job/getpostedjobs'
      resolve(get(url));
    }),
);

export const getSingleJobWithApplicants = createAsyncThunk(
  'getSingleJobWithApplicants',
  (id) =>
    new Promise((resolve, reject) => {
      let url = `job/getjobwithapplicants/${id}`
      resolve(get(url));
    }),
);

export const completeUserJob = createAsyncThunk(
  'completeUserJob',
  ({ job, companion }) =>
    new Promise((resolve, reject) => {
      resolve(patch(`job/completejob/${job}`, { id: companion }));
    }),
);

export const createReview = createAsyncThunk(
  'job',
  (payload) =>
    new Promise((resolve, reject) => {
      resolve(post(`review`, payload));
    }),
);