import React, { useState } from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { getFormValues } from '../../../helper'
import { forgotPassword } from '../../../redux/authSlice/actions'
import VerificationModal from '../../../Components/VerificationModal/VerificationModal'

function ForgotPass() {

    let [email, setEmail] = useState("")
    let [show, setShow] = useState(false)

    let dispatch = useDispatch()

    let error = useSelector(state => state.auth.error)

    const sendCode = (e) => {
        e.preventDefault()
        let obj = getFormValues(e)
        console.log(obj)
        dispatch(forgotPassword({ payload: obj })).then((res) => {
            if (res.payload?.status === "success") {
                setShow(true)
            }
        })
    }

    return (
        <div className=' authMain loginMain container-fluid'>
            <VerificationModal email={email} show={show} resetPass={true} />
            <Row className='h-100'>
                <Col md={6} className='h-100 p-0'>
                    <div className='imageDiv d-flex flex-column align-items-center justify-content-center row-gap-5'>
                        {/* <div className='logoDiv'>
                            <img width={100} src={require('../../../assets/images/logo.png')} />
                        </div> */}
                        <div className='imgDiv text-center'>
                            <img width={"80%"} src={require('../../../assets/images/person.png')} />
                        </div>
                    </div>
                </Col>
                <Col md={6} className='h-100 p-0'>
                    <div className='formDiv d-flex flex-column align-items-center justify-content-center login h-100'>
                        <div className='heading w-100'>
                            <div className='w-100 d-flex align-items-center justify-content-between'>
                                <h6>Welcome to <span>Kaytree</span></h6>
                            </div>
                            <h2>Forgot Password?</h2>
                        </div>

                        <form onSubmit={(e) => sendCode(e)}>
                            <div className='inputDiv'>
                                <label>Email</label>
                                <input onChange={(e) => setEmail(e.target.value)} name='email' type='text' placeholder='Type your Email' />
                            </div>


                            {/* {error?.message &&
                                <div className='error mt-2'>
                                    <Alert variant="danger">
                                        {error?.message}
                                    </Alert>
                                </div>
                            } */}

                            <div className='btn-div'>
                                <button type='submit'>Send Code</button>
                            </div>
                        </form>

                        <div className='formBelow text-center'>
                            <p className='mb-0'>Don’t have an account?  <NavLink className='ml-1' to="/register">Sign Up</NavLink></p>
                        </div>
                        <div className='formBelow text-center mt-0'>
                            <p className='mb-0'>Already have an account?  <NavLink className='ml-1' to="/login">Login</NavLink></p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ForgotPass